import 'swiper/swiper-bundle.min.css'

import styled from '@emotion/styled'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { MessageCircle } from 'react-feather'
import Fade from 'react-reveal/Fade'
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Props as ReviewProps, Review } from './Review'

SwiperCore.use([Autoplay, Pagination, EffectFade])

export interface Props {
  languageCode: string
  reviews: ReviewProps[]
}

export const CustomerReviews = memo(function CustomerReviews({
  languageCode,
  reviews,
}: Props) {
  if (reviews.length < 1) {
    return null
  }

  return (
    <Container>
      <Fade bottom distance="3.75rem">
        <>
          <MessageCircle />
          <Label>
            {useVocabularyData('listen-to-our-guests', languageCode)}
          </Label>
          <Swiper
            allowTouchMove={false}
            autoplay={{ delay: 4000 }}
            effect="fade"
            loop
            pagination={
              reviews.length > 1
                ? {
                    clickable: true,
                  }
                : false
            }
          >
            {uniq(reviews).map((item, index) => (
              <SwiperSlide key={index}>
                <Review {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  max-width: 40.625rem;
  margin: 9.75rem auto 6rem;
  padding: 0 1.5rem;
  text-align: center;
  svg {
    width: auto;
    height: 3rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
    stroke-width: 1;
  }

  .swiper-container {
    display: flex;
    flex-wrap: wrap;
  }
  .swiper-slide {
    height: auto;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
  .swiper-pagination {
    display: flex;
    justify-content: center;
    order: 2;
    margin-top: 3.75rem;
    position: relative;
    bottom: 0;
    span {
      width: 10px;
      height: 10px;
      background: ${({ theme }) => theme.colors.variants.neutralDark4};
      border: 1px solid ${({ theme }) => theme.colors.variants.neutralDark4};
      border-radius: 50%;
      margin: 0 0.625rem;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: transparent;
      }
    }
  }

  @media (max-width: 1023px) {
    max-width: none;
    margin: 7.5rem auto 6rem;
  }

  @media (max-width: 767px) {
    margin-top: 6rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  line-height: 1.125rem;
  margin-top: 1.5rem;
  margin-bottom: 2.25rem;
  text-transform: uppercase;
`
